<template>
    <div class='publicService'>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='publicServiceContent'>
            <div class='publicServiceCondition'>
                <div class="flex publiceCommon">
                    <div class='title'>机构所在地：</div>
                    <div  class='typeName' @click='onLocationCon(index, item.id)' :class='locationActive==index?"active":""' v-for='(item, index) in locationList' :key='index+"l"'>{{item.name}}</div>
                </div>
                <div class="flex publiceCommon publiceCommonBot">
                    <div class='title'>星级评分：</div>
                    <div  class='typeName' @click='onStartCon(index, item.id)' :class='starRatActive==index?"active":""' v-for='(item, index) in starRating' :key='index+"s"'>{{item.name}}</div>
                </div>
            </div>
            <div v-if='list.length > 0' class='listcontent'>
                <div class="flex listItem" v-for='(item, index) in list' :key='index+"L"' @click='onGoDetaie(item.id)'>
                    <div class='leftContent'>
                        <img :src="item.companyPic || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'" alt="">
                    </div>
                    <div class='rightContent'>
                        <div class='name'>{{item.companyName}}</div>
                        <div class='companyName'>{{item.companyName}}</div>
                        <div class='star'>
                            <img v-for='count in item.star' :key='count' :src="require('@/assets/images/dataShare/pic_star@2x.png')" alt="">
                        </div>
                        <div class='bottom flex'>
                            <div>
                                <img :src="require('@/assets/images/dataShare/pl@2x.png')" alt=""> <span>{{item.commentCount}}</span>
                            </div>
                            <div class='center'>
                                <img :src="require('@/assets/images/dataShare/ll@2x.png')" alt=""> <span>{{item.viewCount}}</span>
                            </div>
                            <div>
                                <img :src="require('@/assets/images/dataShare/dd@2x.png')" alt=""> <span>{{item.cityType==1?'海南':'上海'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination v-if='page.total > param.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
            </div>
            <div v-else>
                <no-data></no-data>
            </div>
        </div>

    </div>
</template>
<script>
const serviceList = ['法律服务', '咨询服务', '金融服务', '科创服务', '创业服务'];
import pagination from '@/component/pagination/index.vue';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import noData from '@/component/noData/index.vue';
export default {
    components: {
        pagination,
        nfBreadcrumb,
        noData
    },
    data() {
        return {
            locationActive: 0,
            starRatActive: 0,
            locationList: [{
                id: '',
                name: '全部'
            }, {
                id: 1,
                name: '海南'
            }, {
                id: 2,
                name: '上海'
            }],
            starRating: [{
                id: -1,
                name: '全部'
            }, {
                id: 0,
                name: '一星'
            }, {
                id: 1,
                name: '二星'
            }, {
                id: 2,
                name: '三星'
            }, {
                id: 3,
                name: '四星'
            }, {
                id: 4,
                name: '五星'
            }],
            list: [],
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 10
             },
             param: {
                cityType: "",
                keyWord: "",
                pageNum: 1,
                pageSize: 10,
                serveEndScore: "",
                serveScore: "",
                serveType: ""
             },
             breadList: [{
                name: '南繁服务'
            }, {
                name: ''
            }],
            title: ''
        };
    },
    mounted() {
        this.initData();
        if (this.$route.query && this.$route.query.type) {
            this.param.serveType = this.$route.query.type;
            document.title = serviceList[this.$route.query.type-1];
            this.$set(this.breadList[1], 'name', serviceList[this.$route.query.type-1]);
            this.title = serviceList[this.$route.query.type-1];
        }
    },
    methods: {
        onLocationCon(idx, id) {
            this.locationActive = idx;
            this.param.cityType = id;
            this.initData();
        },
        onStartCon(idx, id) {
            this.starRatActive = idx;
            if (id === -1) {
                this.param.serveScore = '';
                this.param.serveEndScore = '';
            } else {
                this.param.serveScore = id + '.00';
                this.param.serveEndScore = id + '.99';
            }

            this.initData();
        },
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            this.initData(); //改变页码，重新渲染页面
        },
        // 列表
        async initData() {
            this.param.pageNum = this.page.currentPage;
            const api = this.$fetchApi.innovationServeInfo.api;
            const data = await this.$fetchData.fetchPost(this.param, api, "json");
            if (data.code === '200' && data.result) {
                this.list = data.result.rows;
                this.page.total = data.result.total;
            }
        },
        onGoDetaie(id) {
            this.$router.push({
                path: '/publicService/publicServiceDetail',
                query: {
                    id,
                    title: this.title,
                    type: this.$route.query.type
                }
            });
        }
    }


};
</script>
<style lang="less" scoped>
    .publicService{
        font-size: 16px;
        line-height: 16px;
        color: #333;
    }
    .title{
        margin-right:20px;
        height:30px;
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
    }
    .publiceCommon{
       padding:19px 0;
        margin-left:16px;
    }
    .typeName{
        padding:0 11px;
        height:30px;
        line-height: 30px;
        margin-right:10px;
    }
    .active{
        color:#265ae1;
    }
    .listItem{
        margin-top:39px;
    }
    .leftContent{
        margin:0 20px 40px 40px;
        width:270px;
        height:169px;
        img{
            width:100%;
            height:100%;
            border:1px solid #ccc;
            display: inline-block;
        }
    }
    .el-pagination{
        text-align: center;
        margin:40px 0;
    }
    .rightContent{
        .name{
            font-size:24px;
            line-height: 24px;
            margin-bottom:18px;
        }
        .companyName{
            color:#757575;
            font-size:18px;
        }
        .bottom{
            line-height: 24px;
            font-size:20px;
            flex-direction: row;
            margin-top:70px;
            img{
                width:24px;
                height:24px;
                margin-right:20px;
            }
            div:first-child{
                padding-left:0
            }
            div{
                padding:0 34px 0 31px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .center{
                border-right:1px solid #d9d9d9;
                border-left:1px solid #d9d9d9;
                text-align: center;
            }

        }

    }
    .publicServiceContent{
        width:95%;
        margin:0 auto 100px;
    }
    .publicServiceContent{
        font-size: 16px;
        color: #333333;
        .publicServiceCondition{
            border: solid 1px #d9d9d9;
            margin-top:20px;
        }
    }
    .publiceCommonBot{
        border-top: dashed 1px #d9d9d9;
    }
</style>
